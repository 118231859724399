import React, { useState } from "react"
import TestimonialCard from "./TestimonialCard"
import { useWindowWidth } from "../hooks/useWindowWidth"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
library.add(faArrowLeft)
library.add(faArrowRight)

const TestimonialCarousel = ({ heading, testimonials }) => {
  const pageWidth = useWindowWidth()
  const cardsInView = pageWidth >= 769 ? 3 : 1
  const totalPages = Math.ceil(testimonials.length / cardsInView)

  const [page, setPage] = useState(0)
  const [forwardDisabled, disableForward] = useState(totalPages <= 1)
  const [backDisabled, disableBack] = useState(true)

  const changePage = direction => {
    const newPage = direction ? page + 1 : page - 1

    setPage(newPage)
    disableButtons(newPage)
  }

  const disableButtons = page => {
    const forward = page + 1 > totalPages - 1
    const back = page - 1 < 0

    disableForward(forward)
    disableBack(back)
  }

  return (
    <>
      <Row className="mb-2 mb-sm-5">
        <Col lg={8} xs={12} className="text-center text-sm-left">
          <h2>{heading}</h2>
        </Col>
        <Col
          lg={4}
          xs={12}
          className="testimonial-control text-center text-sm-right d-flex flex-wrap align-items-center"
        >
          <div>
            <button
              className="btn btn-link"
              onClick={changePage.bind(this, false)}
              disabled={backDisabled ? "disabled" : null}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="lg" />
            </button>
            <button
              className="btn btn-link"
              onClick={changePage.bind(this, true)}
              disabled={forwardDisabled ? "disabled" : null}
            >
              <FontAwesomeIcon icon={faArrowRight} size="lg" />
            </button>
          </div>
        </Col>
      </Row>
      <Row className="mb-5">
        <div className="testimonialCarousel">
          <div
            className="testimonialCarousel__inner"
            style={{
              left: `-${page * 100}%`,
            }}
          >
            {testimonials.map(testimonial => (
              <TestimonialCard
                quote={testimonial.quote}
                employee={testimonial.name}
                position={testimonial.position}
                headshot={testimonial.headshot}
              />
            ))}
          </div>
        </div>
      </Row>
    </>
  )
}

export default TestimonialCarousel
