import React from "react"
import { graphql, Link } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Layout from "../components/layout"
import Hero from "../components/Hero"
import TestimonialCarousel from "../components/TestimonialCarousel"
import SectionHeading from "../components/SectionHeading"
import Image from "../components/image"
import SEO from "../components/seo"
import { useInView } from "react-intersection-observer"

const IntroSection = ({ careers }) => {
  const { ref, inView } = useInView()
  return (
    <Container as="section">
      <Row className="text-center" ref={ref}>
        <Col lg="12">
          <Image
            src="../img/footer_logo.svg"
            className="d-block mx-auto mb-5"
            style={{
              width: 50,
              transform: !inView ? "translateY(1rem)" : "none",
              opacity: !inView ? 0 : 1,
              transition: "0.5s all ease-out",
            }}
          />
        </Col>
        <Col lg="12">
          <h2
            className="h4 text-center mb-4"
            style={{
              transform: !inView ? "translateY(1rem)" : "none",
              opacity: !inView ? 0 : 1,
              transition: "0.5s all ease-out 0.25s",
            }}
          >
            {careers.gallery_crown}
          </h2>
          <h3
            className="h4 text-center mb-4"
            style={{
              transform: !inView ? "translateY(1rem)" : "none",
              opacity: !inView ? 0 : 1,
              transition: "0.5s all ease-out 0.5s",
            }}
          >
            {careers.gallery_heading}
          </h3>
          {/* <Gallery crown={careers.gallery_crown} heading={careers.gallery_heading} tiles={careers.gallery_images} /> */}
        </Col>
        <Col>
          <p
            className="lead"
            style={{
              transform: !inView ? "translateY(1rem)" : "none",
              opacity: !inView ? 0 : 1,
              transition: "0.5s all ease-out 0.75s",
            }}
          >
            {careers.benefits_heading}
          </p>
        </Col>
      </Row>
    </Container>
  )
}
const BenefitRow = ({ image, heading, body, isEven }) => {
  const { ref, inView } = useInView()
  return (
    <Row className={"culturePoint"} ref={ref}>
      <Col
        lg="6"
        sm="12"
        style={{
          opacity: inView ? "100%" : 0,
          transition: "1s all ease-out",
          transform: inView
            ? "translateX(0)"
            : `translateX(${isEven ? "25%" : "-25%"})`,
        }}
      >
        <div className={"culturePoint__image"}>
          <Image resizeWidth={800} src={image} />
        </div>
      </Col>
      <Col
        lg="6"
        sm="12"
        style={{
          opacity: inView ? "100%" : 0,
          transition: "1s all ease-out",
          transform: inView
            ? "translateX(0)"
            : `translateX(${isEven && "-"}25%)`,
        }}
      >
        <div className="culturePoint__content">
          <h3 className="culturePoint__heading">{heading}</h3>
          <p className="culturePoint__body">{body}</p>
        </div>
      </Col>
    </Row>
  )
}

const CareersPage = ({ data, location }) => {
  const careers = data.careers.edges[0].node
  const jobs = data.jobs.edges

  return (
    <Layout
      hero={
        <Hero
          image={careers.hero_background}
          heading={careers.hero_heading}
          subhead={careers.hero_subhead}
          ctas={careers.hero_ctas}
        />
      }
    >
      <SEO title="Careers" path={location.pathname} />
      <IntroSection careers={careers} />
      <Container fluid={true} as="section">
        {careers.benefits_points.map((point, i) => (
          <BenefitRow isEven={i % 2} {...point} />
        ))}
      </Container>
      <Container as="section">
        <TestimonialCarousel
          heading={careers.testimonials_heading}
          testimonials={careers.testimonials}
        />
      </Container>
      <Container
        as="section"
        id="open-positions"
        fluid={true}
        className="bg-gray"
      >
        <Row>
          <Col className="pb-2 pb-sm-5">
            <SectionHeading
              crown="We're Hiring"
              heading={careers.open_positions_heading}
            />
          </Col>
        </Row>
        <Row className="pb-5 pb-sm-0">
          <Col>
            <Row className="px-md-4">
              {jobs.map(({ node }) => {
                return (
                  <Col lg="3" sm="12" className="py-3 text-left">
                    <Link to={`/careers/${node.slug}/`}>{node.title}</Link>
                    <p
                      dangerouslySetInnerHTML={{ __html: node.job_description }}
                      className="text-ellipsis"
                    ></p>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    careers: allButterPage(filter: { slug: { eq: "careers" } }) {
      edges {
        node {
          hero_heading
          hero_subhead
          hero_background
          hero_ctas {
            label
            url
            icon
            type
          }
          gallery_crown
          gallery_heading
          gallery_images {
            featured_image: image
          }
          benefits_heading
          benefits_points {
            image
            heading
            body
          }
          testimonials_heading
          testimonials {
            name
            position
            quote
            headshot
          }
          open_positions_heading
        }
      }
    }
    jobs: allButterPage(
      filter: { page_type: { eq: "jobs" }, active: { eq: true } }
    ) {
      edges {
        node {
          slug
          title
          active
          job_description
        }
      }
    }
  }
`

export default CareersPage
