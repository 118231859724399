import React from "react"

const TestimonialCard = ({ quote, employee, position, headshot }) => {
  return (
    <blockquote className="testimonial">
      <p className="testimonial__quote">&rdquo;{quote}&ldquo;</p>
      <cite className="testimonial__cite">
        <img src={headshot} alt={employee} />
        {employee}
        <span>{position}</span>
      </cite>
    </blockquote>
  )
}

export default TestimonialCard
